import screenFull from 'screenfull'
import * as THREE from 'three'
import { INTERSECTED, NOT_INTERSECTED } from 'three-mesh-bvh'

const referencePoints = {
  model1: [
    [-0.856145084, 0.839168072, 0.006765349],
    [-0.863209128, -0.886255622, -0.006703234],
    [0.80611968, 0.889884531, -0.000464763],
    [-0.0216043, -0.021117337, 15.19564056],
    [-0.032264199, -1.083060503, 14.06180382],
    [-0.017240399, -1.23761487, 10.40577221],
    [-0.01032, -1.42512095, 6.713850975]
  ],
  model2: [
    [-0.863857985, 0.838982999, 0.015134],
    [-0.865871012, -0.872803986, 0.006869],
    [0.803565025, 0.884643018, 0.001333],
    [-0.016001999, -0.025738001, 15.20278931],
    [-0.016705999, -1.076598048, 14.0680542],
    [-0.006613, -1.239470959, 10.40992546],
    [-0.001242, -1.425724983, 6.715602875]
  ]
}

export const tabs = [
  {
    label: '25 Oct 2022',
    componentName: '2022-Oct-25',
    // 资源路径
    dirs: 'model-v1.ply',
    referencePoint: [...referencePoints.model1]
  },
  {
    label: '7 Jul 2023',
    componentName: '2023-Jul-7',
    // 资源路径
    dirs: 'model-v2.ply',
    oldReferencePoint: [...referencePoints.model1],
    referencePoint: [...referencePoints.model2]
  }
]

export function getDom() {
  const doms = document.getElementsByClassName('model-page__tabs__item')
  for (let i = 0; i < doms.length; i += 1) {
    this.domPosition.push({
      width: doms[i].clientWidth,
      left: doms[i].offsetLeft
    })
  }
}

export function modelExtend(Model, cacheEffect, type) {
  // 是否可以全屏
  if (!screenFull.isEnabled) {
    this.$message({
      message: '您的浏览器不支持！',
      type: 'warning'
    })
  } else {
    screenFull.on('change', () => {
      this.isFull = !this.isFull
    })
  }
  const mouse = (t) => {
    if (cacheEffect[type] === 'rotate') {
      Model.renderer.domElement.style.cursor = t === 'down' ? 'grabbing' : 'grab'
    }

    // 修改鼠标是否按下
    if (t === 'down') {
      Model.isMouseDown = true
    } else Model.isMouseDown = false
  }
  // 鼠标按下监听改变鼠标样式
  Model.renderer.domElement.addEventListener('mousedown', () => mouse('down'))
  Model.renderer.domElement.addEventListener('mouseup', () => mouse('up'))
  Model.renderer.domElement.addEventListener('mouseleave', () => {
    Model.isMouseDown = false
  })
  Model.renderer.domElement.addEventListener('mousemove', (e) => {
    // Model.setLine({
    //   x: e.offsetX,
    //   y: e.offsetY
    // })
    if (cacheEffect[type] === 'reset') Model.pan(e.offsetX, e.offsetY)
    if (cacheEffect[type] !== 'ruler') return
    const bvhMesh = Model.group.getObjectByName(Model.modelGroupName).getObjectByName('bvh')

    if (!bvhMesh) {
      return
    }

    mouse.x = (e.offsetX / Model.renderer.domElement.clientWidth) * 2 - 1
    mouse.y = -(e.offsetY / Model.renderer.domElement.clientHeight) * 2 + 1
    Model.raycaster.setFromCamera(mouse, Model.camera)
    Model.sphereCollision.visible = false

    const inverseMatrix = new THREE.Matrix4()
    inverseMatrix.copy(bvhMesh.matrixWorld).invert()
    Model.raycaster.ray.applyMatrix4(inverseMatrix)

    Model.raycaster.params.Points.threshold = 0.002
    const { threshold } = Model.raycaster.params.Points
    const localThreshold = threshold / ((bvhMesh.scale.x + bvhMesh.scale.y + bvhMesh.scale.z) / 3)
    const localThresholdSq = localThreshold * localThreshold

    const { ray } = Model.raycaster
    let closestDistance = Infinity
    bvhMesh.geometry.boundsTree.shapecast({
      boundsTraverseOrder: (box) => {
        // traverse the closer bounds first.
        return box.distanceToPoint(ray.origin)
      },
      intersectsBounds: (box, isLeaf, score) => {
        // if we've already found a point that's closer then the full bounds then
        // don't traverse further.
        if (score > closestDistance) {
          return NOT_INTERSECTED
        }

        box.expandByScalar(localThreshold)
        return ray.intersectsBox(box) ? INTERSECTED : NOT_INTERSECTED
      },
      intersectsTriangle: (triangle) => {
        const distancesToRaySq = ray.distanceSqToPoint(triangle.a)
        if (distancesToRaySq < localThresholdSq) {
          // track the closest found point distance so we can early out traversal and only
          // use the closest point along the ray.
          const distanceToPoint = ray.origin.distanceTo(triangle.a)
          if (distanceToPoint < closestDistance) {
            closestDistance = distanceToPoint
            Model.sphereCollision.position.copy(triangle.a).applyMatrix4(bvhMesh.matrixWorld)
            Model.sphereCollision.visible = true
          }
        }
      }
    })
  })
  Model.renderer.domElement.addEventListener('click', (e) => {
    if (cacheEffect[type] === 'ruler')
      Model.clickObject3D.apply(Model, [
        {
          x: e.offsetX,
          y: e.offsetY
        }
      ])
  })
}

export function modelOptions(options) {
  return {
    overallHeight: 15.25,
    width: 600,
    height: options.height || 690,
    startCreate() {
      this.loading.text = '开始创建场景...'
    },
    startCreateModel() {
      this.loading.text = '开始创建模型...'
    },
    loadingCompleted() {
      this.loading.text = '模型渲染中...'
    },
    renderCompleted() {
      console.log('渲染完成')
      this.loading.text = '渲染完成'
      // 核心：添加宏任务，等待执行，宏任务会在交互阻塞后执行
      setTimeout(() => {
        this.loading.close()
      })
    },
    loaderError() {
      console.log('加载错误')
      this.loading.spinner = 'el-icon-close'
      this.loading.text = '加载错误！'
      setTimeout(() => {
        this.loading.close()
      }, 500)
    },
    ...options
  }
}

/**
 * 判断是否存在数据，存在则直接添加没有则请求下载
 * @author peng-xiao-shuai
 */
export function addGroup(model, Tabs, type) {
  const currentModel = this.currentTab.modelData

  // 模型群组下子集所有群组隐藏
  model.group.children.forEach((group) => {
    if (group.name !== 'referencePoint') {
      group.visible = false
    }
  })
  model.modelGroupName = this.currentTab.componentName
  // 获取当前tab对应的模型
  if (currentModel) {
    model.options.loadingCompleted.apply(model)
    // 判断model数据是否存在模型中
    if (model.group.getObjectByProperty('name', this.currentTab.componentName)) {
      model.group.getObjectByProperty('name', this.currentTab.componentName).visible = true
      if (model.group.getObjectByName('blackGroup') && model.line) {
        model.line.visible = true
      }
    } else {
      // 创建loading 请不要将创建loading放在 if (currentModel) 上
      model.createLoading()
      model.options.startCreateModel.apply(model)
      // currentTab 存在设置模型显示
      // 为什么这么想请看 model.vue 中 243行
      setTimeout(() => {
        model.group.add(this.currentTab.modelData.clone())
      }, 300)
      setTimeout(() => {
        model.loading.close()
      }, 301)
    }
  } else {
    // 创建loading 请不要将创建loading放在 if (currentModel) 上
    model.createLoading()
    // 重置数据
    model.resetData()
    if (model.line) model.line.visible = false

    this.cacheEffect[type] = ''

    model
      .getIndexedDBData(this.currentTab.dirs, (modelData) => {
        // 这里使用 clone 克隆，进行深拷贝
        Tabs[this.tabsIndex].modelData = modelData.clone()
      })
      .catch(() => {
        model.options.startCreateModel.apply(model)
        // 渲染创建模型
        model.createPLYModel(this.currentTab.dirs, this.currentTab.componentName, (modelData) => {
          // 这里使用 clone 克隆，进行深拷贝
          Tabs[this.tabsIndex].modelData = modelData.clone()
        })
      })
  }
}
